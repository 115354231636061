.announcement-modal {
  .modal-dialog {
    max-width: 650px;
    .modal-header {
      .close {
        @include size(60px);
        opacity: .9;
        background: url("images/icons/close-green.svg") no-repeat center center;
        outline: 0;
      }
    }
    .modal-body {
      padding: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}