header.site-header {
  position: relative;
  z-index: 99;
  .header-top {
    background-color: #DFEAE4;
    .holder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      ul.social-icons {
        @include list-unstyled;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        padding: 15px 0;
        margin-left: 140px;
        li {
          padding: 0 10px;
          &:first-child {
            padding-left: 0;
          }
          &.has-text {
            position: relative;
            bottom: -2px;
          }
          &:not(:last-child) {
            border-right: 1px solid rgba(44, 114, 79, .25);
          }
          a {
            opacity: .6;
            color: $green;
            font-weight: bold;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .header-top-right {
        display: flex;
        align-items: center;
        ul {
          @include list-unstyled;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 15px 0;
          li {
            padding: 0 15px;
            a {
              color: rgba(0, 11, 5, .7);
              font-weight: bold;
              position: relative;
              bottom: -2px;
            }
            &.dropdown {
              a {
                font-weight: normal;
                &:after {
                  position: relative;
                  bottom: -2px;
                  opacity: .5;
                }
              }
              .dropdown-menu {
                background-color: rgba(0, 11, 5, .9);
                min-width: auto;
                padding: 3px 0;
                margin-left: -25px;
                margin-top: 16px;
                &:before {
                  content: ' ';
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 5px 5px 5px;
                  border-color: transparent transparent rgba(0, 11, 5, .9) transparent;
                  position: absolute;
                  top: -5px;
                  right: 12px;
                }
                a {
                  color: #fff;
                  font-size: 14px;
                  &:hover {
                    color: $yellow;
                  }
                }
              }
            }
          }
          &:not(.language-selection) {
            li {
              border-right: 1px solid rgba(44, 114, 79, .25);
            }
          }
        }
        .open-search-button {
          @include size(51px);
          background-color: $yellow;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            background: url("images/icons/search.svg") no-repeat center center;
            display: inline-block;
            @include size(18px);
            transition: all ease .3s;
          }
          &:hover {
            i {
              transform: scale(1.2);
            }
          }
        }
        .language-selection {
          ul {
            padding: 0;
            li.current-lang {
              display: none;
            }
            li.lang-item {
              padding: 0;
              a {
                padding: 0.25rem 1.5rem;
                position: relative;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
  .header-main {
    background-color: $green;
    height: 60px;
    .holder {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      z-index: 2;
      .logo {
        top: -19px;
        position: absolute;
        left: 0;
        img {
          width: 100px;
          position: relative;
          z-index: 3;
        }
        &:before {
          content: " ";
          background: url("images/icons/main-header-logo-ripple-1.svg") no-repeat center center;
          @include size(120px,60px);
          position: absolute;
          z-index: 2;
          left: -10px;
          top: 19px;
          opacity: .2;
        }
        &:after {
          content: " ";
          background: url("images/icons/main-header-logo-ripple-2.svg") no-repeat center center;
          @include size(140px,60px);
          position: absolute;
          z-index: 2;
          left: -21px;
          top: 19px;
          opacity: .2;
        }
        span {
          background: url("images/icons/main-header-logo-ripple-3.png") no-repeat center center;
          @include size(357px,158px);
          position: absolute;
        }
      }
      .menu-right {
        display: flex;
        align-items: center;
        ul.menu {
          @include list-unstyled;
          margin: 0 10px 0 0;
          display: flex;
          align-items: center;
          li {
            position: relative;
            a:hover {
              background-color: #004623;
            }
            &.current-menu-item {
              a {
                background-color: #004623;
              }
            }
            &.menu-item-has-children:hover {
              & > a {
                background-color: #004623;
              }
              & > .sub-menu {
                opacity: 1;
                visibility: visible;
              }
            }
            .menu-item-has-children {
              & > a {
                &:after {
                  content: " ";
                  @include size(5px,8px);
                  background: url("images/icons/menu-dropdown-arrow.svg") no-repeat center center;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  margin-top: -4px;
                }
              }
            }
          }
          & > li {
            & > a {
              color: #fff;
              font-weight: bold;
              font-size: 16px;
              display: block;
              padding: 18px 13px;
              &:hover {
                background-color: #004623;
              }
            }
            &.menu-item-has-children {
              .sub-menu {
                position: absolute;
                left: 0;
                top: 100%;
                @include list-unstyled;
                margin: 0;
                background-color: $green;
                width: 190px;
                opacity: 0;
                visibility: hidden;
                z-index: 99;
                li {
                  a {
                    display: block;
                    color: rgba(255,255,255,.7);
                    font-size: 14px;
                    padding: 12px 14px;
                  }
                }
                .sub-menu {
                  left: 100%;
                  top: 0;
                }
              }
              &:hover {
                & > a {
                  background-color: #004623;
                }
                & > a + .sub-menu {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
      .open-mega-menu-button {
        background-color: rgba(0, 11, 5,.2);
        @include size(51px,60px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        span {
          @include size(18px,2px);
          border-radius: 1px;
          background-color: #fff;
          transition: all ease .3s;
          &:nth-of-type(2) {
            margin: 4px 0;
          }
        }
        &:hover {
          span {
            &:nth-of-type(1) {
              transform: translateX(-3px);
            }
            &:nth-of-type(3) {
              transform: translateX(3px);
            }
          }
        }
      }
    }
    .ripples {
      @include ripple;
    }
  }
  @media(max-width: 1024px) {
    .header-main {
      .holder {
        .menu-right {
          ul.menu {
            & > li > a {
              font-size: 13px;
              padding: 18px 9px;
            }
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    display: none;
  }
}

.mobile-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $green;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  .logo {
    position: absolute;
    left: 50%;
    margin-left: -52px;
    top: 0;
  }
  .mobile-menu-button {
    @include size(18px);
    background: url("images/icons/bars.svg") no-repeat center center;
    transition: none;
  }
  .open-search-button {
    @include size(18px);
    background: url("images/icons/search.svg") no-repeat center center;
  }
  .language-selection {
    position: absolute;
    right: 56px;
    top: 12px;
    @include list-unstyled;
    margin: 0;
    li {
      a {
        color: #fff;
        font-size: 12px;
      }
    }
    li.current-lang {
      display: none;
    }
    li.lang-item {
      padding: 0;
      a {
        padding: 0.25rem 1.5rem;
        position: relative;
        top: 0;
      }
    }
    .dropdown-menu {
      background-color: rgba(0, 11, 5, .9);
      min-width: auto;
      padding: 3px 0;
      margin-left: -25px;
      margin-top: 16px;
      &:before {
        content: ' ';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent rgba(0, 11, 5, .9) transparent;
        position: absolute;
        top: -5px;
        right: 12px;
      }
      ul {
        padding: 0;
      }
      a {
        color: #fff;
        font-size: 12px;
        &:hover {
          color: $yellow;
        }
      }
    }
  }
  @media(max-width: 768px) {
    display: flex;
  }
}

.tooltip.header {
  font-size: 11px;
  color: #fff;
  margin-top: 12px;
  .bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: rgba(0, 11, 5, .9);
      }
    }
  }
  .tooltip-inner {
    background-color: rgba(0, 11, 5, .9);
    padding: 4px 8px 3px;
  }
}

body:not(.home) {
  padding-top: 111px;
  header.site-header .header-main .ripples {
    display: none;
  }
  header.site-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  @media(max-width: 768px) {
    padding-top: 0;
  }
}

html.mm-wrapper_opened {
  .mobile-menu-button {
    background: url("images/icons/close.svg");
    background-size: cover;
    transition: none;
  }
  /*.mobile-header {
    top: 16px;
  }*/
}