.home-page {
  section.welcome {
    margin-bottom: 50px;
    .holder {
      padding: 30px;
      background: url("images/welcome-bg.jpg") $gray-100 no-repeat left center;
      img {
        border: 15px solid #FFFFFF;
        max-height: 340px;
        box-shadow: 0 2px 35px 0 rgba(0,0,0,0.25);
      }
      .text-holder {
        padding-right: 30px;
      }
      h3 {
        color: $yellow;
        text-align: center;
        margin-bottom: 20px;
        font-size: 24px;
        margin-top: 10px;
      }
      p {
        text-align: center;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .btn {
        display: table;
        margin: auto;
      }
    }
  }
  section.awards {
    margin-bottom: 50px;
    .gallery {
      display: flex;
      align-items: center;
      img {
        border: none !important;
      }
    }
  }
  section.reviews {
    position: relative;
    margin-bottom: 8px;
    h3 {
      font-family: 'Mark Offc Pro';
      font-size: 14px;
      background-color: $yellow;
      color: #fff;
      padding: 10px 15px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      font-weight: normal;
    }
    .review-slider {

    }
    .slide {
      position: relative;
      &:not(:last-child):after {
        content: " ";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-right: 8px solid #fff;
        z-index: 9;
      }
      .review-box {
        height: 550px;
        display: flex;
        flex-direction: column;
        padding: 80px 40px 40px;
        position: relative;
        z-index: 4;
        &:before {
          content: " ";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0,0,0,.7);
        }
        h4 {
          font-size: 18px;
          line-height: 26px;
          color: #fff;
          max-width: 70%;
          font-weight: normal;
          padding-bottom: 20px;
          margin-bottom: 20px;
          position: relative;
          &:after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 60px;
            border-bottom: 2px solid $yellow;
          }
        }
        .review-detail {
          margin-bottom: 20px;
          color: #fff;
          font-size: 16px;
          line-height: 27px;
          opacity: .8;
          max-height: 238px;
          overflow: hidden;
        }
        .name {
          font-size: 14px;
          color: $yellow;
          margin-bottom: 20px;
        }
        .stars {
          width: 110px;
        }
      }
    }
    ul.navigation {
      @include list-unstyled;
      margin: 0 0 0 -75px;
      display: flex;
      position: absolute;
      left: 33%;
      top: 10px;
      z-index: 5;
      li {
        margin: 0 3px;
        a {
          @include size(25px);
          padding: 5px;
          display: block;
          &.prev {
            background: url("images/arrow-left.svg") no-repeat center center;
          }
          &.next {
            background: url("images/arrow-right.svg") no-repeat center center;
          }
        }
      }
    }
  }
  section.rooms {
    background: url("images/rooms-bg.jpg") no-repeat center center;
    background-size: cover;
    padding: 80px;
    .rooms-slider {
      .holder {
        box-shadow: 0 2px 35px 0 rgba(0,0,0,0.25);
        position: relative;
        .title {
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          h4 {
            background-color: $black;
            color: #fff;
            margin: 0;
            padding: 15px 40px;
            display: inline-block;
          }
        }
      }
      .navigation {
        @include list-unstyled;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        li {
          margin: 0 5px;
          a {
            @include size(30px);
            display: block;
            &.next {
              background: url("images/arrow-right-black.svg") no-repeat center center;
            }
            &.prev {
              background: url("images/arrow-left-black.svg") no-repeat center center;
            }
          }
        }
      }
    }
    .content-holder {
      font-size: 18px;
      line-height: 32px;
      h2, h3, h4, h5, h6 {
        color: $yellow;
        font-size: 24px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        &:after {
          content: " ";
          position: absolute;
          bottom: 0;
          width: 60px;
          border-bottom: 2px solid $yellow;
          left: 0;
        }
      }
      ul {
        @include list-unstyled;
        li {
          position: relative;
          padding-left: 20px;
          &:before {
            content: " ";
            position: absolute;
            @include size(6px);
            border-radius: 50%;
            background-color: $yellow;
            left: 0;
            top: 13px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    section.welcome {
      .holder {
        img {
          max-height: 300px;
          margin-top: 10px;
        }
      }
    }
    section.reviews {
      .slide {
        .review-box {
          .review-detail {
            max-height: 211px;
          }
          .name, .stars {
            position: relative;
            z-index: 9;
          }
        }
      }
    }
    section.rooms {
      .col-sm-7 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      .content-holder {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    section.welcome {
      .holder {
        text-align: center;
        background-position: top center;
        background-size: cover;
        .text-holder {
          padding-right: 0;
          margin: 30px 0;
          p {
            color: #fff;
          }
        }
        img {
          max-width: 100%;
        }
      }
    }
    section.awards {
      .gallery {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        img {
          max-width: 80%;
          height: auto;
        }
      }
    }
    section.reviews {
      ul.navigation {
        left: 50%;
      }
    }
  }
  @media screen and (max-width: 667px) {
    section.reviews {
      ul.navigation {
        right: 20px;
        left: auto;
      }
      .slide {
        &:not(:last-child):after {
          display: none;
        }
      }
    }
    section.rooms {
      padding: 30px;
      .rooms-slider {
        .holder {
          .title {
            position: initial;
            h4 {
              display: block;
              font-size: 16px;
            }
          }
        }
      }
      .content-holder {
        padding: 0 !important;
      }
    }
  }
}

body.home {
  .mobile-page-title {
    display: none !important;
  }
}