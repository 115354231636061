.page-wrapper.post-page {
  .left-navigation-wrapper {
    h3 {
      background-color: $green;
      padding: 20px 20px 15px;
      margin: 0;
      color: #fff;
      font-size: 26px;
      border-bottom: 1px solid rgba(255,255,255,.1);
      a {
        color: #fff;
      }
    }
    .menu {
      @include list-unstyled;
      background-color: $green;
      padding-bottom: 10px;
      font-size: 15px;
      line-height: 1.8;
      a {
        color: #fff;
      }
      .sub-menu {
        display: none;
      }
      ul {
        @include list-unstyled;
        li {
          a {

          }
        }
      }
      & > li {
        padding: 0 20px;
        & > .sub-menu {
          padding-left: 10px;
        }
        &:first-child {
          padding-top: 10px;
        }
        &:not(:first-child) {
          &.current-menu-item, &.current-menu-ancestor {
            margin-top: 10px;
          }
        }
      }
      & > li.current-menu-item, & > li.current-menu-ancestor {
        background-color: #004924;
        padding: 15px 20px;
        margin-bottom: 15px;
        ul.sub-menu {
          display: block;
          a {
            opacity: .5;
          }
          & > li.current-menu-item {
            & > a {
              position: relative;
              opacity: 1;
              color: $yellow;
              font-weight: bold;
              &:after {
                content: " ";
                display: inline-block;
                margin-left: 10px;
                width: 10px;
                height: 7px;
                background: url("images/icons/menu-right-arrow.svg") no-repeat center center;
                position: relative;
                top: -1px;
              }
            }
          }
          .sub-menu {
            padding-left: 10px;
          }
        }
      }
      & > li.current_page_item, & {
        & > a {
          font-weight: bold;
        }
      }
    }
  }
  .page-content-holder {
    .breadcrumbs {
      font-family: $font-family-base;
      margin-bottom: 30px;
    }
    h1 {
      font-family: $font-family-base;
      font-size: 42px;
      color: $black;
      margin-bottom: 50px;
    }
    .post-date {
      color: rgba(0, 11, 5,.55);
      font-size: 14px;
      font-family: $font-family-base;
    }
    .page-featured-image {
      a {
        display: block;
        img {
          width: 100%;
        }
      }
    }
    font-family: 'Merriweather', serif;
    color: rgba(0, 11, 5,.7);
    font-weight: 300;
    line-height: 1.9;
    h2,h3,h4,h5,h6 {
      font-family: 'Merriweather', serif;
      font-weight: 300;
      color: rgba(0, 11, 5, 1);
      line-height: 1.5;
      margin-bottom: 25px;
    }
    h3 {
      font-size: 22px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 16px;
    }
    .content-editor-holder {
      a {
        color: $blue;
        font-weight: 400;
        text-decoration: underline;
      }
      img.aligncenter {
        display: block;
        margin: auto;
      }
    }
    ul {
      @include list-unstyled;
      li {
        position: relative;
        padding-left: 20px;
        &:before {
          content: " ";
          position: absolute;
          left: 0;
          top: 13px;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $yellow;
        }
      }
    }
    img {
      &.alignleft {
        float: left;
        margin: 0 30px 30px 0;
      }
      &.alignright {
        float: right;
        margin: 0 0 30px 30px;
      }
    }
    .social-share {
      display: flex;
      align-items: center;
      .at-icon-wrapper {
        svg {
          transform: scale(.7);
        }
      }
      .share-text {
        color: rgba(0, 11, 5,.55);
        font-size: 14px;
        font-family: $font-family-base;
        padding-left: 45px;
        margin-left: 20px;
        position: relative;
        top: -2px;
        background: url("images/icons/dots.svg") no-repeat left center;
      }
    }
    .event-wrapper {
      h1 {
        margin-bottom: 30px;
      }
      .event-infos {
        color: $green;
        font-family: $font-family-base;
        margin-bottom: 30px;
      }
      .day-list {
        @include list-unstyled;
        margin: 0;
        li {
          background: #FFFFFF;
          box-shadow: 0 0 30px -1px rgba(0,11,5,0.15);
          border-radius: 10px;
          padding: 20px;
          font-family: $font-family-base;
          margin-bottom: 20px;
          color: $darkgreen;
          position: relative;
          .day-name {
            font-size: 14px;
            font-weight: bold;
            display: block;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 15px;
            &:after {
              background: url(images/icons/divider-green.svg) no-repeat center center;
              content: " ";
              position: absolute;
              width: 35px;
              height: 3px;
              bottom: -5px;
              left: 0;
            }
          }
          .day-number {
            display: block;
            font-weight: bold;
            font-size: 46px;
            line-height: 50px;
            margin-bottom: 5px;
            position: relative;
            .end-day {
              float: right;
              /*&:before {
                content: "-";
                position: absolute;
                left: 50%;
                opacity: .2;
                margin-left: -10px;
                top: -1px;
                font-size: 40px;
              }*/
            }
          }
          .month {
            font-size: 14px;
            font-weight: bold;
            display: block;
            text-transform: uppercase;
            line-height: 15px;
            &.start {
              float: left;
            }
            &.end {
              float: right;
            }
          }
          .year {
            font-size: 14px;
            font-weight: bold;
            display: block;
            text-transform: uppercase;
            line-height: 15px;
            text-align: center;
            background-color: rgba(0,0,0,.2);
            color: #fff;
            padding: 5px 0 2px;
            border-radius: 0 0 6px 6px;
          }
          .hour {
            font-size: 14px;
            font-weight: bold;
            display: block;
            opacity: .4;
          }
          hr {
            border-color: transparent;
            margin-bottom: 10px;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .page-content-holder {
      h1 {
        padding-top: 120px;
        font-size: 32px;
      }
      .social-share {
        .share-text {
          display: none;
        }
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  @media(max-width: 737px) {
    .left-navigation-wrapper {
      display: none;
    }
    .page-content-holder {
      h1 {
        margin-bottom: 15px;
      }
      .breadcrumbs {
        display: none;
      }
      .social-share {
        margin-bottom: 15px;
      }
      .gallery-grid {
        margin-top: 15px;
      }
    }
  }
}