.banner-slider-wrapper {
  .ripples {
    @include ripple;
    z-index: 10;
    top: 0;
    left: 50%;
    margin-left: -693px;
  }
  .banner-slider {
    width: 100%;

    .swiper-slide {
      height: 390px;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      background-position: center right;
      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 40%;
        background-image: linear-gradient(90deg, #F2F7F4 0%, rgba(242,247,244,.9) 70%, rgba(242,247,244,0.00) 100%);
        z-index: 1;
      }

      .text-holder {
        position: absolute;
        left: 50%;
        margin-left: -562px;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        z-index: 100;
        h4 {
          color: $black;
          font-size: 44px;
          line-height: 46px;
          margin-bottom: 15px;
          max-width: 60%;
        }
        .description {
          font-size: 14px;
          color: rgba(0, 11, 5, .55);
          margin-bottom: 25px;
        }
        .btn {
          color: #fff;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
    .slider-arrow {
      &.next {
        background: url('images/icons/banner-slider-arrow-right.svg') no-repeat center right;
      }
      &.prev {
        background: url('images/icons/banner-slider-arrow-left.svg') no-repeat center right;
      }
    }
  }
  @media(max-width: 1024px) {
    .ripples {
      display: none;
    }
    .banner-slider {
      .swiper-slide {
        .text-holder {
          margin-left: -350px;
        }
      }
    }
  }
  @media(max-width: 768px) {
    .slider-arrow {
      display: none;
    }
    .banner-slider {
      .swiper-slide {
        background-size: cover;
        background-position: top 0 right -120px;
        &:before {
          background-image: linear-gradient(-180deg, rgba(242,247,244,0.00) 34%, #FFFFFF 64%, #FFFFFF 77%);
          right: 0;
          bottom: 0px;
          top: -60px;
        }
        .text-holder {
          margin-left: 0;
          left: 0;
          right: 0;
          top: 170px;
          align-items: center;
          h4 {
            text-align: center;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 10px;
            max-width: 90%;
          }
          .description {
            text-align: center;
            padding: 0 20px;
            font-size: 13px;
            line-height: 17px;
            margin-bottom: 10px;
            br {
              display: none;
            }
          }
        }
      }
      .banner-slider-pagination {
        bottom: 0;
        .swiper-pagination-bullet {
          background: #DFEAE4;
          &.swiper-pagination-bullet-active {
            background: $yellow;
          }
        }
      }
    }
  }
}