/*
Theme Name: Terakki
Theme URI: http://www.terakki.org.tr/
Author: Aykan Burçak
Author URI: http://www.aykanburcak.com
Description:
Version: 1.0.0
License: GNU General Public License
License URI: https://www.gnu.org/licenses/gpl.html
Tags: terakki
Text Domain: terakki
*/

// Custom bootstrap variable overrides
@import "styles/bootstrap_variable_overrides";


@import "styles/fonts";

//Plugins
@import "styles/font-awesome";
@import "styles/plugins/swiper";
@import "styles/plugins/jquery.mhead";
//@import "styles/plugins/datatables";



// Bootstrap and its default variables
@import "styles/scss/bootstrap/bootstrap";

@import "styles/variables";
@import "styles/mixins";
@import "styles/general";
@import "styles/layout";
@import "styles/module";

@import "styles/elements/header";
@import "styles/elements/footer";
@import "styles/elements/main-slider";
@import "styles/elements/fancybox";
@import "styles/elements/slider-settings";
@import "styles/elements/breadcrumbs";
@import "styles/elements/forms";
@import "styles/elements/page-title";
@import "styles/elements/gallery-slider";
@import "styles/elements/main-menu";
@import "styles/elements/search";
@import "styles/elements/fullcalendar";
@import "styles/elements/event-calendar";
@import "styles/elements/announcement-modal";
@import "styles/elements/mobile-menu";
@import "styles/elements/mobile-page-title";
@import "styles/elements/contact-form";

//VC Modules
@import "styles/elements/vc_modules/corporate-section";
@import "styles/elements/vc_modules/title";
@import "styles/elements/vc_modules/post-box";
@import "styles/elements/vc_modules/pub-box";
@import "styles/elements/vc_modules/posts-grid";
@import "styles/elements/vc_modules/posts-slider";
@import "styles/elements/vc_modules/icon-box";
@import "styles/elements/vc_modules/campus-box";
@import "styles/elements/vc_modules/button";
@import "styles/elements/vc_modules/banner-slider";


@import "styles/pages/homepage";
@import "styles/pages/post-page";
@import "styles/pages/static-page";
@import "styles/pages/search-results";
