.slider-arrow {
  position: absolute;
  top: 50%;
  margin-top: -79px;
  z-index: 9;
  cursor: pointer;
}

.slider-arrow.next {
  @include size(100px, 158px);
  background: url("images/icons/main-slider-arrow-right.svg") no-repeat center right;
  right: 0;
  margin-top: -79px;
  outline: none;
}

.slider-arrow.prev {
  @include size(100px, 158px);
  background: url("images/icons/main-slider-arrow-left.svg") no-repeat center left;
  left: 0;
  margin-top: -79px;
  outline: none;
}

.slider-pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  z-index: 9;
  text-align: center;
}