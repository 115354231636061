.corporate-section {
  background-size: cover;
  background-position: bottom center;
  .corporate-contact-box {
    border-radius: 10px 10px 0 0;
  }
  .wpb_text_column {
    ul {
      @include list-unstyled;
      li {
        a {
          transition: all .2s ease-in-out;
          &:hover {
            padding-left: 5px;
          }
        }
      }
    }
  }
  .section-title-wrapper.title-white + .wpb_text_column {
    ul {
      li {
        a {
          color: #fff;
        }
      }
    }
  }
  .section-title-wrapper.align-right + .wpb_text_column {
    ul {
      li {
        a {
          &:hover {
            padding-right: 5px;
          }
        }
      }
    }
  }
  @media(max-width: 737px) {
    padding: 0 15px !important;
    .corporate-contact-box {
      position: relative;
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 10px 10px 0 0;
        background-color: rgba(251, 191, 29, .8);
        display: block;
      }
      .vc_column-inner {
        padding: 20px 0 !important;
      }
    }
    .vc_column-inner {
      padding: 20px !important;
      .wpb_text_column {
        ul {
          li {
            a {
              font-size: 13px;
            }
          }
        }
      }
    }
    .section-title-wrapper {
      &.align-left {
        text-align: left;
        &:before {
          left: 0;
          margin-left: 0;
        }
      }
      &.align-right {
        text-align: right;
        &:before {
          right: 0;
          left: auto;
          margin-left: 0;
        }
      }
    }
  }
}