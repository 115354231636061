.mobile-menu {
  display: none;
  z-index: 99999;
  .close-menu {
    position: absolute;
    right: 18px;
    top: 18px;
  }

  .mm-navbars_top {
    border-bottom: none;
    height: 50px;
  }

  .mm-navbar {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #004C25;
    border-bottom: none;
    height: 50px;

    .mm-btn_prev {
      position: initial;
      width: 42px;

      &:not(.mm-hidden) + .mm-navbar__title {
        color: #fff;
      }
    }

    .mm-navbar__title {
      padding: 0;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255,255,255,.7);
      text-align: left;
      font-weight: normal;
    }
  }

  .mm-panels {
    background-color: #fff;

    .mm-navbar {
      display: none;
    }

    .mm-panel {
      background-color: $green;
      padding-top: 10px;
    }
  }

  .mm-listview {
    .mm-btn_next:after {
      border-color: #5e5e5e;
      width: 12px;
      height: 12px;
    }

    .mm-listitem {
      border-bottom-color: #fff;

      > a {
        font-size: 18px;
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        color: #fff;
        font-weight: bold;

        &.mm-listitem__btn:not(.mm-listitem__text) {
          border-left: none;
          &.mm-btn_next {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            left: 0;
            &:after {
              border-color: #fff;
              @include size(9px)
            }
          }
        }

        &.mm-listitem__text:not(.mm-listitem__btn) {
          padding: 14px 20px;
        }
      }
      &:after {
        border-color: rgba(0,0,0,.1);
        left: 0;
      }
    }
  }

}

.mm-btn_close:after, .mm-btn_close:before {
  display: none;
}

.mm-btn_next:after, .mm-btn_prev:before {
  width: 9px;
  height: 9px;
  border-color: #fff;
}