.btn {
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  padding: 12px 30px 10px;
  &.btn-default {
    border: 1px solid rgba(0, 11, 5, .7);
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(123, 167, 146,.5);
    }
  }
}