.mobile-page-title {
  display: none;
  background-color: $yellow;
  margin: 0 -15px;
  padding: 110px 0 10px;
  h1 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
  }
  @media(max-width: 768px) {
    display: block;
    margin: 0 -39px;
  }
  @media(max-width: 737px) {
    margin: 0 -15px;
  }

}