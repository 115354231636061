.section-title-wrapper {
  position: relative;
  margin-bottom: 35px;
  &.align-left {
    text-align: left;
    &:before {
      left: 0;
    }
  }
  &.align-right {
    text-align: right;
    &:before {
      right: 0;
    }
  }
  &.align-center {
    text-align: center;
    &:before {
      left: 50%;
      margin-left: -17.5px;
    }
  }
  &:before {
    content: " ";
    position: absolute;
    @include size(35px,3px);
    bottom: -10px;
  }
  &.divider-green {
    &:before {
      background: url("images/icons/divider-green.svg") no-repeat center center;
    }
  }
  &.divider-yellow {
    &:before {
      background: url("images/icons/divider-yellow.svg") no-repeat center center;
    }
  }
  &.title-black {
    color: $darkgreen;
  }
  &.title-white {
    color: $white;
  }
  @media(max-width: 768px) {
    h2 {
      font-size: 24px;
    }
  }
  @media(max-width: 737px) {
    &.align-left, &.align-right {
      text-align: center;
      &:before {
        left: 50%;
        margin-left: -17.5px;
      }
    }
  }
}