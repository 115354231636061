@font-face {
  font-family: 'Averta';
  src: url('fonts/Averta-Bold.eot');
  src: url('fonts/Averta-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Averta-Bold.woff2') format('woff2'),
  url('fonts/Averta-Bold.woff') format('woff'),
  url('fonts/Averta-Bold.ttf') format('truetype'),
  url('fonts/Averta-Bold.svg#Averta-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('fonts/Averta-Semibold.eot');
  src: url('fonts/Averta-Semibold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Averta-Semibold.woff2') format('woff2'),
  url('fonts/Averta-Semibold.woff') format('woff'),
  url('fonts/Averta-Semibold.ttf') format('truetype'),
  url('fonts/Averta-Semibold.svg#Averta-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('fonts/Averta-Regular.eot');
  src: url('fonts/Averta-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Averta-Regular.woff2') format('woff2'),
  url('fonts/Averta-Regular.woff') format('woff'),
  url('fonts/Averta-Regular.ttf') format('truetype'),
  url('fonts/Averta-Regular.svg#Averta-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

