body {
  scroll-behavior: smooth;

  &.admin-bar {
    .site-header {
      //top: 32px;
    }
  }
  &.block-scroll {
    position: fixed;
  }
}

.menu-image {
  display: none !important;
}

.main-wrapper {
  position: relative;
  z-index: 9;
}

section.vc_section, section.vc_section.vc_section-has-fill {
  padding: 50px 0;
}

b,
strong {
  font-weight: bold;
}

.gallery-grid {
  .gallery-item {
  }
  p {
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: bold;
  }
}

.dataTables_paginate {
  text-align: right;
  margin-top: 20px;
  font-family: $font-family-base;
  .previous.paginate_button, .next.paginate_button {
    opacity: .6;
    width: auto;
    border: none;
  }
  .paginate_button {
    border: 1px solid #DFEAE4;
    border-radius: 3px;
    @include size(30px);
    line-height: 30px;
    text-align: center;
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
    &.current {
      background-color: $green;
      color: #fff;
      font-weight: bold;
    }
  }
}
.dataTables_filter {
  display: none;
}

.photo-gallery-wrapper {
  .gallery-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
  }
  h5 {
    text-align: center;
    margin-bottom: 0;
  }
}

.grecaptcha-badge { opacity:0;}

@media(max-width: 1024px) {
  #wrapper {
    overflow-x: hidden;
  }
}

@media(max-width: 737px) {
  #wrapper {
    overflow-x: hidden;
  }
  h2 {
    font-size: 28px;
  }
  section.vc_section, section.vc_section.vc_section-has-fill {
    padding: 30px 15px;
  }
}