.search-wrapper {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: all ease .3s;
  opacity: 0;
  display: flex;
  visibility: hidden;
  &:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $yellow;
    opacity: .85;
    z-index: 1;
  }
  .form-holder {
    position: relative;
    z-index: 2;
    form {
      display: flex;
      align-items: center;
    }
    input {
      height: 70px;
      background-color: #fff;
      border: none;
      border-radius: 8px 0 0 8px;
      font-size: 24px;
      font-weight: bold;
      color: $green;
      line-height: 70px;
      padding-left: 24px;
      width: 450px;
      &:focus {
        outline: 0;
      }
      &::placeholder {
        color: $green;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $green;
      }

      &::-ms-input-placeholder {
        color: $green;
      }
    }
    button {
      background-color: $green;
      border: none;
      @include size(70px);
      border-radius: 0 8px 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 25px;
      }
    }
  }
  .closer {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 40px;
    top: 40px;
    background: url("images/icons/close.svg") no-repeat center center;
    z-index: 9;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    animation: showSearch ease .8s;
  }
  @media(max-width: 737px) {
    .closer {
      right: 20px;
      top: 20px;
    }
    .form-holder {
      input {
        width: 80%;
        font-size: 20px;
      }
    }
    &.active {
      animation: none;
    }
  }
}

@keyframes showSearch {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  70% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}