.post-box {
  position: relative;
  margin-bottom: 30px;
  a {
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 9;
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(-180deg, rgba(0,11,5,0.1) 43%, rgba(0,11,5,0.9) 100%);
      transition: all ease .3s;
    }
    &:hover {
      .text-holder {
        h4 {
          transform: translateY(-10px);
        }
        .excerpt {
          opacity: 1;
          transition: all ease .3s;
        }
      }
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .text-holder {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    z-index: 20;
    h4 {
      color: #fff;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 0;
      transition: all ease .3s;
    }
    .excerpt {
      font-family: $headings-font-family;
      color: rgba(255,255,255,.85);
      font-size: 14px;
      line-height: 16px;
      opacity: 0;
      transition: all ease .3s;
    }
    .date {
      color: rgba(255,255,255,.85);
      font-size: 12px;
    }
  }
  &.big {
    .text-holder {
      left: 30px;
      bottom: 30px;
      h4 {
        font-size: 32px;
        line-height: 36px;
      }
      .date {
        padding-left: 40px;
        position: relative;
        &:before {
          content: " ";
          position: absolute;
          width: 30px;
          top: 50%;
          left: 0;
          border-top: 1px solid $yellow;
        }
      }
    }
  }
  &.gallery {
    .text-holder {
      h4 {
        transform: translateY(0);
      }
      .excerpt {
        display: none;
      }
      p {
        display: none;
      }
    }
  }
  @media(max-width: 768px) {
    .text-holder {
      h4 {
        font-size: 14px;
        line-height: 16px;
      }
    }
    &.big {
      .text-holder {
        left: 15px;
        bottom: 15px;
        h4 {
          font-size: 20px;
        }
      }
    }
  }
  @media(max-width: 737px) {
    margin-bottom: 10px;
    .text-holder {
      h4 {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &.big {
      .text-holder {
        .date {
          padding-left: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}