.main-slider {
  height: 100vh;
  width: 100%;
  position: relative;
  .logo-holder {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    z-index: 9;
    top: 15px;

    img {
      position: relative;
      z-index: 4;
      width: 100px;
      height: 100px;
    }

    .ripple {
      &:before {
        content: ' ';
        position: absolute;
        @include size(130px, 223px);
        background-color: #000;
        opacity: .3;
        border-radius: 65px;
        left: -15px;
        top: -106px;
      }

      &:after {
        content: ' ';
        position: absolute;
        @include size(198px, 287px);
        background-color: #000;
        opacity: .1;
        border-radius: 99px;
        left: -49px;
        top: -138px;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      @include size(268px, 335px);
      background-color: #000;
      opacity: .1;
      border-radius: 170px;
      left: -83px;
      top: -148px;
    }
  }

  .swiper-container {
    width: 100%;

    .swiper-slide {
      height: 100vh;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;

      &:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 2;
      }

      .btn-dark {
        position: absolute;
        bottom: 120px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        background-color: rgba(0,0,0,.6);
        border: none;
        display: flex;
        align-items: center;
        opacity: .8;
        i {
          @include size(18px);
          background: url("images/icons/play-button.svg") no-repeat center center;
          position: relative;
          top: -1px;
          margin-right: 7px;
        }
      }

      .video-holder {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-size: cover;
        max-height: 115vh;
        iframe {
          max-height: 115vh;
          margin-right: -40px;
        }
      }

      .text-holder {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 1140px;
        margin: auto;
        position: relative;
        z-index: 3;
        color: #fff;

        h2 {
          max-width: 60%;
          span {
            font-size: 70px;
            line-height: 80px;
            margin-bottom: 40px;
            box-shadow: inset 0 -8px rgba(0,86,43,.3),
          }
        }

        p {
          font-size: 20px;
          line-height: 32px;
          max-width: 60%;
          margin-bottom: 75px;
        }

        .btn {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          padding: 20px 60px;
        }
      }
    }
  }

  .swiper-button-next {
    @include size(100px, 158px);
    background: url("images/icons/main-slider-arrow-right.svg") no-repeat center right;
    right: 0;
    margin-top: -79px;
    outline: none;
  }

  .swiper-button-prev {
    @include size(100px, 158px);
    background: url("images/icons/main-slider-arrow-left.svg") no-repeat center left;
    left: 0;
    margin-top: -79px;
    outline: none;
  }

  .swiper-pagination {
    bottom: 75px;
  }

  .go-bottom {
    @include size(85px, 51px);
    background: url("images/icons/main-slider-go-bottom-background.svg") no-repeat center bottom;
    position: absolute;
    bottom: -.5px;
    left: 50%;
    margin-left: -42.5px;
    z-index: 5;

    i {
      @include size(15px, 22px);
      background: url("images/icons/main-slider-go-bottom-arrow.svg") no-repeat center center;
      position: absolute;
      left: 50%;
      margin-left: -7.5px;
      top: 50%;
      margin-top: -11px;
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
  }

  @media(max-height: 768px) {
    .swiper-container {
      .swiper-slide {
        .video-holder {
          /*iframe {
            max-height: 36vh;
            margin-right: -40px;
            margin-top: 50% !important;
          }*/
        }
        .text-holder {
          h2 {
            font-size: 60px;
            line-height: 70px;
            margin-bottom: 30px;
          }
          p {
            margin-bottom: 50px;
          }
        }
      }
    }
  }
  @media(max-width: 1024px) {
    .swiper-container {
      .swiper-slide {
        .text-holder {
          width: 100%;
          padding-left: 90px;
          h2 {
            span {
              font-size: 50px;
              line-height: 60px;
            }
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .swiper-container {
      .swiper-slide {
        .text-holder {
          width: 100%;
          padding-left: 90px;
          padding-right: 90px;
          h2 {
            max-width: 100%;
            text-align: center;
            span {
              font-size: 50px;
              line-height: 60px;
            }
          }
          p {
            max-width: 100%;
            text-align: center;
          }
          .btn {
            margin: 0 auto;
          }
        }
      }
    }
    .logo-holder {
      display: none;
    }
  }
  @media(max-width: 737px) {
    .swiper-button-next, .swiper-button-prev {
      display: none;
    }
    .swiper-container {
      .swiper-slide {
        .text-holder {
          padding-left: 40px;
          padding-right: 40px;
          h2 {
            max-width: 100%;
            text-align: center;
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 20px;
            span {
              font-size: 28px;
              line-height: 38px;
            }
          }
          p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
          }
          .btn {
            padding: 10px 20px 8px;
          }
        }
      }
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}