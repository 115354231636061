$blue: #2C5DC4;
$green: #00562B;
$darkgreen: #000B05;
$yellow: #FBBF1D;
$dark-yellow: #c5c924;
$gray-100: rgba(0,0,0,.1);
$gray-200: rgba(0,0,0,.2);
$gray-300: rgba(0,0,0,.3);
$gray-400: rgba(0,0,0,.4);
$gray-500: rgba(0,0,0,.5);
$gray-600: rgba(0,0,0,.6);
$gray-700: rgba(0,0,0,.7);
$gray-800: rgba(0,0,0,.8);
$dark-gray: #404041;
$black: #000B05;

a {
  transition: all ease .3s;
}

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1160px
) !default;

$grid-gutter-width: 30px !default;

$body-bg: #fff;

$link-color: $dark-gray;
$link-hover-decoration: none;
$link-hover-color: black;


$btn-padding-x: 30px;
$btn-padding-y: 8px;
$btn-border-radius: 5px;
$btn-focus-width: 0;


$input-btn-padding-y: .48rem;
$input-border-color: $gray-200;
$input-border-radius: 0;
$input-placeholder-color: $gray-300;

$h1-font-size: 42px;
$h2-font-size: 32px;
$h4-font-size: 18px;


$font-size-base: 1rem;
$font-family-base: 'Averta';
$font-weight-base: normal;
$headings-font-family: 'Averta';
$headings-font-weight: 600;
$headings-margin-bottom: 30px;


$modal-sm: 350px;

$custom-control-indicator-checked-bg: $yellow;
$custom-control-indicator-checked-color: $dark-gray;