.search-results-page {
  .search-summary {
    font-size: 22px;
    color: $darkgreen;
    border-bottom: 1px solid #E5E6E6;
    padding-bottom: 30px;
  }
  .search-result-box {
    display: inline-block;
    width: 100%;
    padding: 30px 0;
    border-bottom: 1px solid #E5E6E6;
    h4 {
      font-size: 19px;
      font-weight: bold;
      color: $green;
      margin-bottom: 10px;
      .text-highlight {
        background-color: $yellow;
      }
    }
    p {
      color: rgba(0,11,5,.7);
      .text-highlight {
        background-color: $yellow;
      }
    }
    .breadcrumbs {
      .breadcrumb-single-item {
        &:not(:last-child) {
          &:after {
            content: " . ";
          }
        }
      }
    }
    img {
      width: 140px;
      height: auto;
      margin-right: 20px;
      float: left;
    }
  }
  .pagination {
    justify-content: flex-end;
    margin-top: 50px;
    .page-numbers {
      border: 1px solid #DFEAE4;
      @include size(30px);
      text-align: center;
      line-height: 30px;
      margin: 0 3px;
      border-radius: 3px;
      color: rgba(0,11,5,.5);
      &.current {
        background-color: $green;
        color: #fff;
        font-weight: bold;
      }
      &.next {
        width: auto;
        border: none;
        margin-left: 10px;
        color: $darkgreen;
        text-decoration: underline;
      }
      &.prev {
        width: auto;
        border: none;
        margin-right: 10px;
        color: $darkgreen;
        text-decoration: underline;
      }
    }
  }
  .new-search-box {
    form {
      position: relative;
      box-shadow: 0 0 30px -1px rgba(0,11,5,0.15);
      border-radius: 8px;
      input {
        background-color: #fff;
        border: none;
        height: 50px;
        border-radius: 8px;
        padding: 0 15px;
      }
      button {
        border-radius: 0 8px 8px 0;
        background-color: $green;
        height: 50px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  @media(max-width: 737px) {
    .new-search-box {
      display: none;
    }
  }
}