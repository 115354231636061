.posts-grid {
  display: inline-block;
  width: 100%;
  div[class*='column'] {
    float: left;
    padding: 0 15px;
  }
  .posts-row {
    margin: 0 -15px;
    .column-9 {
      width: 75%;
    }
    .column-8 {
      width: 66.66666%;
    }
    .column-6 {
      width: 50%;
    }
    .column-4 {
      width: 33.33333%;
    }
    .column-3 {
      width: 25%;
    }
  }
  &.layout_seven, &.layout_nine {
    .post-box.big {
      img {
        max-height: 480px;
      }
    }
  }
  &.layout_ten {
    .post-box.big {
      img {
        max-height: 478px;
      }
    }
  }
  @media(max-width: 737px) {
    .posts-row {
      .column-9, .column-8, .column-6, .column-4, .column-3 {
        width: 100%;
      }
    }
  }
}