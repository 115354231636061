.contact-form {
  .btn.btn-success {
    font-size: 16px;
    padding: 0 45px;
    height: 44px;
    width: 148px;
  }
  .wpcf7-submit {
    width: 148px;
  }
  .submit-clone {
    display: none;
  }
}