.wpcf7-form {
  br {
    display: none;
  }
  p {
    margin-bottom: 0;
  }
  .form-group {
    position: relative;
    margin-bottom: 25px;
    label {
      color: rgba(0, 11, 5, .55);
      font-size: 12px;
      line-height: 14px;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 15px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
      z-index: 2;
      background-color: #fff;
    }
    .wpcf7-form-control-wrap {
      .form-control {
        border: none;
        border-bottom: 1px solid rgba(0, 11, 5, .25);
        padding-left: 0;
        font-weight: bold;
        color: $black;
        padding-bottom: 3px;
        &:focus {
          outline: none;
          box-shadow: none;
          border-bottom-color: $darkgreen;
        }
        &.custom-select {
          border-radius: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: url("images/icons/select-arrow.svg") no-repeat right center;
        }
      }
    }
    &.active label {
      top: -8px;
      color: rgba(0, 11, 5, 1);
    }
  }
  .custom-checkbox {
    label {
      font-size: 14px;
      color: rgba(0, 11, 5, .55);
      a {
        color: rgba(0, 11, 5, 1);
        text-decoration: underline;
      }
      &:before {
        background-color: #fff;
        border: 1px solid #BFC2C0;
        box-shadow: none !important;
        margin-top: -2px;
      }
      &:after {
        margin-top: -2px;
        border-color: $yellow;
      }
    }
  }
  .wpcf7-form-control.wpcf7-acceptance {
    font-size: 14px;
    color: rgba(0, 11, 5, .55);
    margin-bottom: 15px;
    a {
      color: rgba(0, 11, 5, 1) !important;
      text-decoration: underline;
    }
    .wpcf7-list-item {
      margin-left: 0;
      label {
        input {
          opacity: 0;
          position: absolute;
          & + .wpcf7-list-item-label {
            &:before {
              @include size(16px);
              border-radius: 2px;
              border: 1px solid #BFC2C0;
              display: inline-block;
              margin-right: 10px;
              position: relative;
              bottom: -4px;
            }
          }
          &:checked {
            & + .wpcf7-list-item-label {
              &:before {
                background: url("images/icons/check.svg") $green no-repeat center center;
                border-color: $green;
              }
            }
          }
        }
      }
    }
  }
  .form-actions {
    display: block;
    text-align: right;
    margin-top: 10px;
    .wpcf7-submit {
      height: 44px;
      border-radius: 5px;
      background-color: $green;
      color: #fff;
      line-height: 44px;
      text-align: center;
      border: none;
      font-weight: bold;
      font-size: 16px;
      padding: 0 45px;
    }
    .ajax-loader {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background: url("images/loader.gif") rgba(255,255,255,.7) no-repeat center center;
    }
  }
  span.wpcf7-not-valid-tip {
    font-size: 12px;
    color: $red;
    display: block;
    margin-top: 5px;
  }
  @media(max-width: 737px) {
    .form-actions {
      margin-bottom: 10px;
    }
  }
}