@mixin boxshadow {
  -webkit-box-shadow: 3px 3px 3px 0px rgba(184, 185, 187, 1);
  -moz-box-shadow: 3px 3px 3px 0px rgba(184, 185, 187, 1);
  box-shadow: 3px 3px 3px 0px rgba(184, 185, 187, 1);
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ripple {
  position: absolute;
  left: -113px;
  top: 60px;
  width: 355px;
  height: 155px;
  overflow: hidden;
  z-index: 1;
  span {
    &:first-child {
      @include size(141px);
      background-color: #DFEAE4;
      opacity: .3;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      margin-left: -70.5px;
      bottom: 110px;
    }

    &:nth-of-type(2) {
      @include size(248px);
      background-color: #DFEAE4;
      opacity: .3;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      margin-left: -124px;
      bottom: 55px;
      z-index: 1;
    }

    &:nth-of-type(3) {
      @include size(355px);
      background-color: #DFEAE4;
      opacity: .3;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      margin-left: -177.5px;
      bottom: 0;
      z-index: 1;
    }
  }
}