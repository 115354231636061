.campus-box {
  background: #fff;
  box-shadow: 0 20px 40px 0 rgba(0,11,5,0.10);
  border-radius: 10px;
  padding: 25px;
  position: relative;
  a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
  iframe {
    width: 100%;
    height: 330px;
    margin-bottom: 20px;
  }
  .section-title-wrapper {
    margin-bottom: 20px;
  }
  .address {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .phone {
    font-size: 14px;
    display: block;
    position: relative;
    a {
      position: initial;
    }
  }
  @media(max-width: 737px) {
    margin-bottom: 15px;
  }
}