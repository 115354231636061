.breadcrumbs {
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 11, 5,.4);
  a {
    color: rgba(0, 11, 5,.7);
  }
  .breadcrumb_last {
    color: rgba(0, 11, 5,.4);
  }
  &.in-banner-slider {
    position: absolute;
    left: 50%;
    margin-left: -562px;
    padding-left: 3px;
    top: 40px;
    z-index: 9;
  }
  @media(max-width: 1024px) {
    &.in-banner-slider {
      left: 36px;
      margin-left: 0;
    }
  }
  @media(max-width: 768px) {
    &.in-banner-slider {
      display: none;
    }
  }
}