.gallery-slider {
  width: 100%;

  .swiper-slide {
    height: 390px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    img {
      width: 100%;
    }
  }

  .slider-arrow {
    &.next {
      background: url('images/icons/banner-slider-arrow-right.svg') no-repeat center right;
    }

    &.prev {
      background: url('images/icons/banner-slider-arrow-left.svg') no-repeat center right;
    }
  }
}