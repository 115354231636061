.calendar-print-button {
  font-family: $font-weight-base;
}

#event-calendar {
  padding: 20px 0 50px;
  h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .fc-bg {
    background-color: rgba(255,255,255,.7);
  }
  table {
    font-family: $font-family-base;
    thead {
      tr {
        th {
          border-color: #CBDACF;
          padding: 0;
        }
        td {
          padding: 0;
        }
      }
    }
    th.fc-day-header {
      background-color: #E5E8D5;
      text-align: left;
      padding: 10px 18px 8px;
      color: $green;
      font-size: 13px;
    }
    tbody {
      tr {
        td {
          border-color: #CBDACF;
          padding: 0;
          background-color: #fff;
          a {
            text-decoration: none;
          }
          &.fc-event-container {
            padding: 15px;
            .fc-event {
              margin: 0;
              background-color: #fcf1d2;
              text-align: center;
              padding: 8px 3px 3px 3px;
              border: none;
              color: $darkgreen;
              &:hover {
                color: $yellow;
              }
              .fc-content {
                display: flex;
                white-space: normal;
                flex-direction: column;
                .fc-title {
                  font-weight: bold;
                  font-size: 14px;
                  margin-bottom: 5px;
                }
                .date {
                  font-size: 12px;
                  opacity: .4;
                }
              }
            }
          }
        }
      }
    }
  }
  th.fc-today {
    background-color: $green !important;
    color: #fff !important;
  }

  td.fc-today {
    background: #FFF !important;
    border: none !important;
    border-top: 1px solid #ddd !important;
    font-weight: bold;
  }
  .fc-next-button, .fc-prev-button {
    border: none;
    background: $yellow;
    color: $green;
    padding: 0 5px;
  }
  .fc-today-button {
    background: $yellow;
    color: $green;
    border: none;
    text-shadow: none;
    text-transform: capitalize;
  }
  .fc-button-group {
    .fc-month-button {
      &.fc-state-default {
        background: $green;
        color: #fff;
        font-weight: bold;
        text-shadow: none;
        padding: 0 10px;
        opacity: .9;
      }
      &.fc-state-active {
        opacity: 1;
      }
      &:focus {
        outline: 0;
      }
    }
    .fc-basicWeek-button {
      &.fc-state-default {
        background: $green;
        color: #fff;
        font-weight: bold;
        text-shadow: none;
        padding: 0 10px;
        opacity: .9;
      }
      &.fc-state-active {
        opacity: 1;
      }
      &:focus {
        outline: 0;
      }
    }
  }
  button:focus {
    outline: 0 !important;
  }
  @media(max-width: 737px) {
    padding: 0 0 15px;
    h2 {
      margin-bottom: 15px;
      font-size: 18px;
      text-align: center;
      display: block;
      width: 100%;
    }
    .fc-toolbar {
      margin-bottom: 0;
      .fc-left {
        float: none;
        display: flex;
      }
      .fc-right {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        float: none;
        .fc-month-button, .fc-basicWeek-button {
          display: none;
        }
      }
    }
  }
}

.page-template-modular-page {
  .calendar-print-button {
    display: none;
  }
}

@media print {
  body.printable-calendar {
    padding-top: 0;
    .site-header, .site-footer, .info-request-form {
      display: none;
    }
    .page-wrapper {
      &.static-page {
        .page-title-wrapper {
          display: none;
        }
        .calendar-print-button {
          display: none;
        }
        .content-holder {
          & > .row {
            justify-content: center;
            .col-sm-3 {
              display: none;
            }
          }
        }
      }
    }
  }
}