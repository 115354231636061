.posts-slider {
  width: 100%;
  .swiper-slide {
    height: 420px;
    background-size: cover;
    a {
      display: inline-block;
      width: 100%;
      position: absolute;
      z-index: 9;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      &:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(-180deg, rgba(0,11,5,0.1) 43%, rgba(0,11,5,0.8) 100%);
      }
      .text-holder {
        position: absolute;
        right: 15px;
        left: 30px;
        bottom: 50px;
        z-index: 2;
        h4 {
          color: #fff;
          font-size: 32px;
          line-height: 36px;
          margin-bottom: 0;
          transition: all ease .3s;
        }
        .date {
          color: rgba(255,255,255,.85);
          font-size: 12px;
          padding-left: 40px;
          position: relative;
          &:before {
            content: " ";
            position: absolute;
            width: 30px;
            top: 50%;
            left: 0;
            border-top: 1px solid $yellow;
          }
        }
      }
      &:hover {
        .text-holder {
          h4 {
            transform: translateY(-10px);
          }
        }
      }
    }
  }
  .all-news-button {
    position: absolute;
    right: 25px;
    bottom: 15px;
    font-weight: bold;
    color: #fff;
    font-size: 14px;
    z-index: 99;
  }
  .school-news-slider {
    .swiper-slide {
      height: 494px;
    }
  }
  @media(max-width: 768px) {
    .swiper-slide {
      a {
        .text-holder {
          h4 {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
  @media(max-width: 737px) {
    margin: 0 -15px 80px;
    width: auto;
    .all-news-button {
      bottom: auto;
      top: 20px;
    }
    .slider-arrow {
      display: none;
    }
    .school-news-slider {
      .swiper-slide {
        height: 350px;
      }
    }
  }
}