.page-title-wrapper {
  background: rgb(253,225,62);
  background: linear-gradient(127deg, rgba(253,225,62,1) 0%, rgba(251,191,29,1) 100%);
  margin-bottom: 30px;
  .ripples {
    @include ripple;
    top: 0;
    left: 50%;
    margin-left: -693px;
  }
  .holder {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 99;
    h1 {
      font-size: 44px;
      margin-bottom: 10px;
    }
  }
  @media(max-width: 768px) {
    padding: 50px 0 10px;
    .ripples {
      display: none;
    }
  }
  @media(max-width: 737px) {
    padding: 110px 0 10px;
    background-color: $yellow;
    margin-bottom: 0;
    .holder {
      height: auto;
      .right {
        display: none;
      }
      .left {
        width: 100%;
        h1 {
          text-align: center;
          font-size: 20px;
          margin-bottom: 0;
        }
        .breadcrumbs {
          display: none;
        }
      }
    }
  }
}