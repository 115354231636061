.site-footer {
  border-top: 20px solid $yellow;
  .footer-top {
    background-color: #DFEAE4;
    padding: 50px 0;
    .wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .menu-holder {
        display: flex;
        align-items: flex-start;
        img {
          margin-right: 35px;
        }
        ul.menu {
          @include list-unstyled;
          margin: 0;
          display: flex;
          align-items: flex-start;
          & > li.menu-item-has-children {
            & > a {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 5px;
              display: block;
            }
            ul {
              @include list-unstyled;
              margin: 0;
              li {
                a {
                  font-size: 14px;
                  color: rgba(0, 11, 5,.7);
                  line-height: 20px;
                }
              }
            }
            &:not(:last-child) {
              margin-right: 65px;
            }
          }
        }
      }
      .icons-badges-holder {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        ul.social-menu {
          @include list-unstyled;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          li {
            a {
              background-color: #9FC0AF;
              color: #fff;
              @include size(60px,50px);
              display: block;
              text-align: center;
              line-height: 50px;
              font-size: 24px;
            }
            &:not(:last-child) {
              border-right: 1px solid #fff;
            }
            &:first-child {
              a {
                border-radius: 5px 0 0 5px;
              }
            }
            &:last-child {
              a {
                border-radius: 0 5px 5px 0;
              }
            }
          }
        }
        ul.badges {
          @include list-unstyled;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          li {
            &:not(:first-child) {
              margin-left: 25px;
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    padding: 20px 0;
    background-color: #fff;
    font-size: 14px;
    .holder {
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        position: absolute;
        right: 0;
        img {
          height: 15px;
          width: auto;
          opacity: .3;
        }
      }
    }
    .copyright {
      opacity: .5;
      margin-right: 20px;
    }
    ul {
      @include list-unstyled;
      margin: 0;
      display: flex;
      align-items: center;
      li {
        margin-right: 20px;
        a {
          font-weight: bold;
        }
      }
    }
  }
  @media(max-width: 1024px) {
    .footer-top {
      .wrapper {
        .icons-badges-holder {
          ul.badges {
            li {
              &:not(:first-child) {
                margin-left: 10px;
              }
              img {
                max-width: 60px;
              }
            }
          }
        }
      }
    }
  }
  @media(max-width: 768px) {
    .footer-top {
      .wrapper {
        flex-direction: column;
        .icons-badges-holder {
          width: 100%;
          align-items: center;
          margin-top: 30px;
          h5 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media(max-width: 737px) {
    margin-top: 30px;
    .footer-top {
      position: relative;
      .wrapper {
        .menu-holder {
          flex-direction: column;
          ul.menu {
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            li {
              display: flex;
              flex-direction: column;
              flex: 50%;
              width: 50%;
              max-width: 50%;
            }
            & > li.menu-item-has-children {
              margin-bottom: 40px;
              &:not(:last-child) {
                margin-right: 0;
              }
            }
          }
          img {
            position: absolute;
            top: -40px;
            left: 50%;
            margin-left: -25px;
            margin-right: 0;
          }
        }
      }
    }
    .footer-bottom {
      .holder {
        flex-direction: column-reverse;
        ul {
          flex-direction: column;
          margin-bottom: 20px;
        }
        .logo {
          right: auto;
          left: 0;
        }
      }
    }
  }
  &.en {
    .footer-top {
      .wrapper {
        .menu-holder {
          ul.menu {
            display: flex;
            flex-wrap: wrap;
            & > li {
              flex: 1 1 auto;
              max-width: 25%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    @media(max-width: 737px) {
      .footer-top {
        .wrapper {
          .menu-holder {
            ul.menu {
              & > li {
                flex: 1 1 auto;
                max-width: 50%;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.page-overlay {
  position: fixed;
  z-index: 99;
  background-color: rgba(0,0,0,.6);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all ease .3s;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.info-request-form {
  position: relative;
  z-index: 100;
  overflow-x: hidden;
  .open-info-request-form {
    position: fixed;
    right: 20px;
    bottom: 10px;
    z-index: 99;
    opacity: 1;
    .text {
      background: $green;
      color: $yellow;
      font-weight: bold;
      border-radius: 5px;
      padding: 12px 25px;
      width: 181px;
      position: absolute;
      right: 23px;
      z-index: 2;
      bottom: 25px;
      transition: all ease .3s;
    }
    .icon {
      @include size(48px);
      background: url("images/icons/info-request.svg") no-repeat center center;
      display: inline-block;
      position: relative;
      z-index: 3;
    }
    &:hover {
      .text {
        transform: translateX(-5px);
      }
    }
  }
  .form-holder {
    position: fixed;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    padding: 30px;
    right: 20px;
    bottom: 0;
    max-width: 500px;
    z-index: 9999;
    transition: all ease .3s;
    visibility: hidden;
    opacity: 0;
    transform: translateY(30px);
    .section-title-wrapper {
      margin-bottom: 15px;
    }
    .close-info-request-form {
      background: url("images/icons/close-green.svg") no-repeat center center;
      position: absolute;
      right: 30px;
      top: 30px;
      @include size(16px);
    }
    .wpcf7-form {
      .form-group {
        margin-bottom: 15px;
        position: relative;
        .wpcf7-form-control-wrap {
          position: initial;
        }
        .wpcf7-not-valid-tip {
          position: absolute;
          right: 0;
          bottom: -17px;
          background-color: $red;
          color: #fff;
          padding: 2px 5px;
          font-size: 10px;
          border-radius: 0 0 5px 5px;
        }
        &.active {
          label {
            top: -4px;
          }
        }
      }
      .wpcf7-form-control.wpcf7-acceptance {
        font-size: 12px;
      }
    }
    p.warning {
      font-size: 10px;
      line-height: 12px;
      opacity: .6;
    }
    div.wpcf7-response-output {
      padding: 5px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      text-align: center;
      border: none;
      font-size: 11px;
      line-height: 11px;
      &.wpcf7-validation-errors, &.wpcf7-acceptance-missing {
        background-color: $yellow;
        color: $black;
        font-size: 14px;
        line-height: 14px;
        padding: 7px 35px;
      }
      &.wpcf7-spam-blocked {
        background-color: $red;
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        padding: 7px 35px;
      }
      &.wpcf7-mail-sent-ok {
        background-color: $green;
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        padding: 7px 35px;
      }
    }
    .form-actions {
      .recipient {
        position: absolute;
        visibility: hidden;
      }
    }
    .wpcf7-submit {
      visibility: hidden;
      position: absolute;
    }
  }
  .wpcf7-submit {
    visibility: hidden;
    position: absolute;
  }
  &.active {
    .form-holder {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }
  }
  @media(max-width: 737px) {
    z-index: 9999;
    .open-info-request-form {
      .text {
        display: none;
      }
    }
    .form-holder {
      padding: 15px;
      width: 100%;
      left: 0;
      right: 0;
      h4 {
        margin-bottom: 10px;
      }
      .wpcf7-form {
        .form-group {
          margin-bottom: 15px;
          .form-control {
            height: 40px;
          }
        }
      }
    }
  }
}