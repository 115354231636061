.icon-box {
  a {
    display: inline-block;
    width: 100%;
    height: 120px;
    background-color: #F2F7F4;
    border-radius: 5px;
    border: 2px solid #F2F7F4;
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
    figure {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      img {
        opacity: .5;
        transition: all ease .3s;
        height: 36px;
        width: auto;
      }
    }
    h5 {
      font-size: 14px;
      color: rgba(0, 11, 5, .7);
      text-transform: uppercase;
      margin-bottom: 0;
      display: block;
      padding: 0 10px;
    }
    &:hover {
      border-color: $yellow;
      img, h5 {
        opacity: 1;
      }
    }
  }
  @media(max-width: 768px) {
    a {
      padding: 20px 10px;
      h5 {
        padding: 0;
        font-size: 12px;
      }
    }
  }
}