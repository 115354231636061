.page-wrapper {
  &.static-page {
    .page-content-holder {
      .pub-box {
        display: flex;
        align-items: flex-start;
        position: relative;
        margin-bottom: 30px;

        figure {
          margin-bottom: 0;
          background: #FFFFFF;
          box-shadow: 0 20px 40px 0 rgba(0, 11, 5, 0.10);
          padding: 10px;
          display: flex;
          align-items: center;
          margin-right: 25px;

          img {
            width: 190px;
            height: auto;
          }
        }

        .text-holder {
          font-family: $font-family-base;
          height: 100%;

          h4 {
            font-size: 20px;
            font-family: $font-family-base;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            opacity: .7;
            line-height: 18px;
          }
          ul {
            font-size: 14px;
            max-height: 88px;
            overflow: hidden;
            li {
              margin-bottom: 0;
              line-height: 18px;
              padding-left: 12px;
              &:before {
                top: 5px;
              }
            }
          }

          a.button {
            font-size: 14px;
            font-weight: bold;
            border: 1px solid rgba(0, 11, 5, .7);
            border-radius: 5px;
            text-align: center;
            line-height: 35px;
            padding: 10px 30px;
            color: $black;
            text-decoration: none;
            &:hover {
              border-color: $yellow;
              background-color: $yellow;
              color: #fff;
            }
          }
        }
      }
    }
  }

  @media(max-width: 737px) {
    &.static-page {
      .page-content-holder {
        .pub-box {
          figure {
            img {
              height: 150px;
            }
          }
          .text-holder {
            h4 {
              font-size: 16px;
              line-height: 18px
            }
            p {
              font-size: 13px;
              line-height: 15px;
            }
            a {
              left: 172px;
            }
          }
        }
      }
    }
  }
}