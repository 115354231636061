.page-wrapper.static-page {
  .left-navigation-wrapper {
    h3 {
      background-color: $green;
      padding: 20px 20px 15px;
      margin: 0;
      color: #fff;
      font-size: 26px;
      border-bottom: 1px solid rgba(255,255,255,.1);
    }
    .menu {
      @include list-unstyled;
      background-color: $green;
      padding-bottom: 10px;
      font-size: 15px;
      line-height: 1.8;
      a {
        color: #fff;
      }
      .sub-menu {
        display: none;
      }
      ul {
        @include list-unstyled;
        li {
          a {

          }
        }
      }
      & > li {
        padding: 0 20px;
        & > .sub-menu {
          padding-left: 10px;
        }
        &:first-child {
          padding-top: 10px;
        }
        &:not(:first-child) {
          &.current-menu-item, &.current-menu-ancestor {
            margin-top: 10px;
          }
        }
      }
      & > li.current-menu-item, & > li.current-menu-ancestor {
        background-color: #004924;
        padding: 15px 20px;
        margin-bottom: 15px;
        & > ul.sub-menu {
          display: block;
          a {
            opacity: .5;
          }
          li.current-menu-item {
            & > a {
              position: relative;
              opacity: 1;
              color: $yellow;
              font-weight: bold;
              &:after {
                content: " ";
                display: inline-block;
                margin-left: 10px;
                width: 10px;
                height: 7px;
                background: url("images/icons/menu-right-arrow.svg") no-repeat center center;
                position: relative;
                top: -1px;
              }
            }
          }
          .sub-menu {
            padding-left: 10px;
          }
          & > li.current-menu-item, & > li.current-menu-ancestor {
            & > ul.sub-menu {
              display: block;
              & > li.current-menu-item, & > li.current-menu-ancestor {
                & > ul.sub-menu {
                  display: block;
                  & > li.current-menu-item, & > li.current-menu-ancestor {
                    & > ul.sub-menu {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
      & > li.current_page_item, & {
        & > a {
          font-weight: bold;
        }
      }
    }
  }
  .page-content-holder {
    .page-featured-image {
      float: right;
      margin-left: 40px;
      margin-bottom: 30px;
    }
    .full-featured-image {
      width: 100%;
      max-height: 390px;
      overflow: hidden;
      margin-bottom: 10px;
      display: inline-block;
    }
    font-family: 'Merriweather', serif;
    color: rgba(0, 11, 5,.7);
    font-weight: 300;
    line-height: 1.9;
    .section-title-wrapper {
      h2 {
        font-family: $font-family-base;
        font-weight: 600;
      }
    }
    .wpcf7-form {
      font-family: $font-family-base;
    }
    h1,h2,h3,h4,h5,h6 {
      font-family: 'Merriweather', serif;
      font-weight: 300;
      color: rgba(0, 11, 5, 1);
      line-height: 1.5;
      margin-bottom: 25px;
    }
    h3 {
      font-size: 22px;
    }
    h4 {
      font-size: 20px;
    }
    h5 {
      font-size: 16px;
    }
    ul {
      @include list-unstyled;
      li {
        position: relative;
        padding-left: 20px;
        &:before {
          content: " ";
          position: absolute;
          left: 0;
          top: 13px;
          display: inline-block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: $yellow;
        }
      }
    }
    img.alignright {
      float: right;
      margin-left: 30px;
      margin-bottom: 30px;
    }
    img.alignleft {
      float: left;
      margin-right: 30px;
      margin-bottom: 30px;
    }
    img.aligncenter {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 30px;
    }
    table {
      width: 100%;
      thead {
        tr {
          background-color: #fff !important;
        }
      }
      tr {
        &:nth-child(odd) {
          background-color: #F2F7F4;
        }
        td, th {
          padding: 10px 20px;
          &:not(:last-child) {
            border-right: 1px solid #E5E6E6;
          }
        }
      }
      &.graduated-table {
        font-family: $font-family-base;
        font-size: 14px;
        margin-top: 80px;
        th {
          font-size: 16px;
          position: relative;
          width: 25%;
          input {
            background-color: #fff;
            border: none;
            box-shadow: 0 5px 20px -1px rgba(0,11,5,0.12);
            border-radius: 4px;
            padding-left: 20px;
            position: absolute;
            left: 0;
            right: 0;
            width: 90%;
            height: 40px;
            line-height: 40px;
            top: -50px;
            font-size: 14px;
            outline: 0;
          }
        }
        & + .dataTables_info {
          display: none;
        }
      }
    }
    #event-calendar {
      table {
        th.fc-day-header {
          padding: 10px 12px 8px;
        }
      }
    }
    .social-share {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      .at-icon-wrapper {
        svg {
          transform: scale(.7);
        }
      }
      .share-text {
        color: rgba(0, 11, 5,.55);
        font-size: 14px;
        font-family: $font-family-base;
        padding-left: 45px;
        line-height: 40px;
        margin-left: 20px;
        position: relative;
        top: -2px;
        background: url("images/icons/dots.svg") no-repeat left center;
      }
      .text-transformer {
        display: flex;
        align-items: center;
        a {
          @include size(32px,18px);
          display: inline-block;
          &.decrease {
            background: url("images/icons/decrease.svg") no-repeat bottom center;
          }
          &.normalize {
            background: url("images/icons/normalize.svg") no-repeat bottom center;
            border-right: 1px solid #DFEAE4;
            border-left: 1px solid #DFEAE4;
          }
          &.increase {
            background: url("images/icons/increase.svg") no-repeat bottom center;
          }
        }
      }
    }
    .post-box {
      .text-holder {
        bottom: 40px;
        transition: height ease .3s;
        h4 {
          color: #fff;
          font-weight: bold;
          font-family: $font-family-base;
          margin-bottom: 0;
          transition: all ease .3s;
          transform: translateY(30px);
          position: absolute;
          bottom: 38px;
        }
        p {
          color: #fff;
          font-size: 13px;
          font-family: $font-family-base;
          line-height: 16px;
          opacity: 0;
          visibility: hidden;
          position: relative;
          left: 0;
          bottom: 0;
          transition: all ease .3s;
          margin-bottom: 0;
          transform: translateY(30px);
          height: 32px;
        }
      }
      .date {
        font-family: $font-family-base;
        color: rgba(255,255,255,.85);
        font-size: 12px;
        position: absolute;
        left: 15px;
        bottom: 15px;
      }
      &:hover {
        a:before {
          background-image: linear-gradient(-180deg, rgba(0,11,5,0.3) 22%, rgba(0,11,5,0.9) 100%);
          transition: all ease .3s;
        }
        .text-holder {
          h4 {
            transform: translateY(-40px);
          }
          p {
            opacity: 1;
            visibility: visible;
            position: relative;
            transition: all ease .3s;
            transform: translateY(0);
          }
        }
      }
      &.gallery {
        .text-holder {
          left: 20px;
          bottom: -10px;
          h4 {
            transform: translateY(0);
          }
        }
        &:hover {
          .text-holder h4 {
            color: $yellow;
          }
        }
      }
    }
    .loader {
      @include size(100px);
      display: inline-block;
      background: url("images/loader.gif") no-repeat left center;
      background-size: contain;
    }
    .pagination {
      opacity: 0;
    }
    .static-page-content-holder {
      a {
        color: $blue;
        text-decoration: underline;
      }
    }
  }
  @media(max-width: 768px) {
    .page-content-holder {
      .infinite-scroll-post-list {
        margin-top: 15px;
      }
      .post-box {
        .text-holder {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media(max-width: 737px) {
    .left-navigation-wrapper {
      display: none;
    }
    .static-page-content-holder {
      padding-top: 15px;
    }
    .page-content-holder {
      .full-featured-image {
        margin: 0 -15px;
        width: auto;
      }
      .social-share {
        margin-top: 10px;
        .share-text {
          display: none;
        }
      }
      .dataTables_wrapper {
        overflow-x: scroll;
      }
      table.graduated-table {
        width: 660px;
      }
    }
  }
}