.main-menu-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 9999;
  overflow: hidden;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,-webkit-transform 0s cubic-bezier(.645,.045,.355,1) .8s;
  transition: clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,transform 0s cubic-bezier(.645,.045,.355,1) .8s;
  transition: clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,transform 0s cubic-bezier(.645,.045,.355,1) .8s,-webkit-clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,-webkit-transform 0s cubic-bezier(.645,.045,.355,1) .8s;
  -webkit-clip-path: circle(0 at 50% 63px);
  clip-path: circle(0 at 50% 63px);
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #002010;
    opacity: .9;
    z-index: 2;
  }
  .logo-holder {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    z-index: 9;
    top: 15px;

    img {
      position: relative;
      z-index: 4;
      width: 100px;
    }

    .ripple {
      &:before {
        content: ' ';
        position: absolute;
        @include size(130px, 223px);
        background-color: #000;
        opacity: .3;
        border-radius: 65px;
        left: -15px;
        top: -106px;
      }

      &:after {
        content: ' ';
        position: absolute;
        @include size(198px, 287px);
        background-color: #000;
        opacity: .1;
        border-radius: 99px;
        left: -49px;
        top: -138px;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      @include size(268px, 335px);
      background-color: #000;
      opacity: .1;
      border-radius: 170px;
      left: -83px;
      top: -148px;
    }
  }
  .menu-ana-menu-container {
    max-width: 70%;
    margin: 245px auto 0;
    .menu {
      @include list-unstyled;
      margin: 0;
      position: relative;
      z-index: 3;
      & > li {
        text-align: center;
        margin-bottom: 20px;
        transition: width 1s ease;
        width: 100%;
        & > a {
          color: $yellow;
          font-size: 34px;
          letter-spacing: 2px;
          font-weight: bold;
          white-space: nowrap;
        }
        &:first-child > a, &:last-child > a {
          color: #fff;
        }
        &.submenu-active {
          &:before {
            content: " ";
            position: absolute;
            left: 300px;
            bottom: 10px;
            top: 0;
            border-right: 1px solid rgba(255,255,255,.3);
            animation: showMenuBorder ease 1s;
          }
          & > a {
            position: relative;
            &:before {
              content: " ";
              position: absolute;
              left: 229px;
              top: 9px;
              @include size(11px,18px);
              background: url("images/icons/menu-active-right-arrow.svg") no-repeat center center;
              animation: showActiveArrow ease 1s;
              transition: all ease .3s;
            }
          }
          .sub-menu {
            display: block;
            position: absolute;
            left: 370px;
            top: 5px;
            @include list-unstyled;
            animation: showSubMenu ease .8s;
            & > li > a {
              text-align: left;
              color: rgba(255,255,255,.7);
              display: block;
              font-size: 28px;
              line-height: 50px;
              &:hover {
                color: #fff;
              }
            }
          }
          &.has-tab-menu {
            & > .sub-menu {
              align-items: center;
              justify-content: space-between;
              width: 350px;
              display: flex;
              & > li {
                & > a {
                  font-size: 20px;
                  font-weight: bold;
                  color: rgba(255,255,255,.5);
                  line-height: 32px;
                  &.active {
                    color: $yellow;
                    border-bottom: 2px solid $yellow;
                    & + .sub-menu {
                      display: block;
                      left: 0;
                      right: 0;
                      top: 65px;
                    }
                  }
                }
                &:nth-of-type(2) {
                  & > a {
                    &.active + .sub-menu {
                      li a {
                        text-align: right;
                      }
                    }
                  }
                }
              }
              .sub-menu {
                display: none;
              }
            }
          }
        }
      }
      .sub-menu {
        display: none;
      }
    }
    &.submenu-active {
      .menu {
        & > li {
          width: 0;
        }
      }
    }
  }
  .closer {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 40px;
    top: 40px;
    background: url("images/icons/close.svg") no-repeat center center;
    z-index: 9;
  }
  &.shown {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: -webkit-clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,-webkit-transform 0s cubic-bezier(.645,.045,.355,1) 0s;
    transition: clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,transform 0s cubic-bezier(.645,.045,.355,1) 0s;
    transition: clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,transform 0s cubic-bezier(.645,.045,.355,1) 0s,-webkit-clip-path .8s cubic-bezier(.645,.045,.355,1) 0s,-webkit-transform 0s cubic-bezier(.645,.045,.355,1) 0s;
    -webkit-clip-path: circle(1100px at 50% 63px);
    clip-path: circle(144% at 50% 63px);
  }
  .menu-image {
    display: none;
  }
  .bg-holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all ease .5s;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
  @media(max-width: 1440px) {
    .menu-ana-menu-container {
      margin-top: 180px;
    }
  }
  @media(max-height: 700px) {
    .menu-ana-menu-container {
     .menu {
       & > li {
         margin-bottom: 10px;
         & > a {
           font-size: 28px;
         }
         &.submenu-active {
           .sub-menu {
             & > li > a {
               font-size: 22px;
               line-height: 36px;
             }
           }
         }
       }
     }
    }
  }
  &.en {
    .menu-ana-menu-container {
      .menu > li.submenu-active {
        a:before {
          left: 302px;
        }
        &:before {
          left: 340px;
        }
        .sub-menu {
          left: 390px;
        }
      }
      .menu {
        & > li.submenu-active.has-tab-menu > .sub-menu {
          width: 470px;
        }
      }
    }
  }
}


@keyframes showSubMenu {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  70% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes showMenuBorder {
  0% {
    opacity: 0;
    bottom: 100%;
  }
  70% {
    opacity: 0;
    bottom: 100%;
  }
  100% {
    opacity: 1;
    bottom: 10px;
  }
}

@keyframes showActiveArrow {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  70% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}